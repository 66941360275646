<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import * as cloneDeep from "lodash.clonedeep";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Switches from "vue-switches";
export const CHAIPAY_APP_URL = process.env.VUE_APP_CHAIPAY_FRONTEND_URL;
// import FilterBtn from '../../../components/filter-btn.vue';
import { paymentMethods } from "@/state/helpers";
import {
  FETCH_SETTLEMENTS_SUMMARY_RESULTS,
  FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY,
  FETCH_CROSS_BORDER_PAYMENT_METHODS,
  CROSS_BORDER_UPDATE_ENABLE_AND_DEFAULT_STATUS,
} from "@/state/actions.type";
// import Multiselect from 'vue-multiselect';

export default {
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    // FilterBtn,
    Switches
  },
  data() {
    // const startDate = new Date();
    // const endDate = new Date();
    // startDate.setDate(startDate.getDate() - 200);
    return {
      selectedCountry:"",
      selectedmethodArray:[],
      selectedmethod:"",
      listOfMethods:{},
      appliedFilter: 'month',
      appliedtype: 'amount',
      appliedAnalytics:'feeMismatch',
      selectedChannel:"",
      selectedExpandBy:"",
      expandByObj:{key:'',value:'Expand By'},
      expandBy:[{key:'',value:'Expand By'}, {key:'currency',value:'Country'},{key:'method',value:'Payment Method'},{key:'channel',value:'Payment channel'}],
      paymentLinksData: [],
      mor_filter: "",
      isFilterApplied: false,
      showFilterSection:false,
      filter:{
        currency:[],
        method:[],
        channel:[]
      }
    };
  },
  created() {
      this.$store.dispatch(`crossBorderPaymentMethods/${FETCH_CROSS_BORDER_PAYMENT_METHODS}`);
      this.paymentLinksData = cloneDeep(this.paymentLinksDataFromStore);
  },

  computed: {
    paymentLinksDataFromStore() {
      return this.$store.state.crossBorderPaymentMethods.methods;
    },

    paymentMethodsDataFromStore() {
      return this.$store.state.crossBorderPaymentMethods.countryList;
    },

    CountryData() {
      return this.$store.state.auth.currentUser.country;
    },

    isFetchingTransactions() {
      return this.$store.state.settlements.isFetchingData;
    },
    rows() {
      return this.$store.state.settlements.total_elements;
    },

    items() {
      return [
        {
          text: "Remittance",
          active: true,
        },
         {
          text: "Payment Mthods",
          active: true,
        },
      ];
    },
    title() {
      return "Payment Mthods";
    },
  },
  watch: {
    paymentLinksDataFromStore() {
      this.paymentLinksData = cloneDeep(this.paymentLinksDataFromStore);
    },
    paymentMethodsDataFromStore() {
      if(this.selectedCountry){
        this.selectedmethodArray = Object.keys(this.paymentLinksData[this.selectedCountry])
        this.listOfMethods = this.paymentLinksData[this.selectedCountry][this.selectedmethod]
      }else{
        this.selectedCountry = cloneDeep(this.paymentMethodsDataFromStore[0]);
      }
    },
    selectedCountry(){
      if(this.selectedCountry){
        this.selectedmethodArray = Object.keys(this.paymentLinksData[this.selectedCountry])
        this.selectedmethod =  Object.keys(this.paymentLinksData[this.selectedCountry])[0];
        this.listOfMethods = this.paymentLinksData[this.selectedCountry][this.selectedmethod]
      }
    },
    selectedmethod(){
      if(this.selectedCountry){
        this.listOfMethods = this.paymentLinksData[this.selectedCountry][this.selectedmethod]
      }
    },
    selectedExpandBy(){
      var from_date = this.pickerDates.startDate
      var to_date = this.pickerDates.endDate

      if(this.selectedExpandBy){
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_EXPAND_BY}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          field:this.selectedExpandBy,
          frequency:this.appliedFilter,
          recon_type:"reconciled"
        });
      }else{
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          source:this.selectedChannel,
          frequency:this.appliedFilter,
          recon_type:"reconciled"
        });
      }
      this.updateColumns();
    },
    expandByObj(){
      if(this.expandByObj){
        if(Object.keys(this.expandByObj).length > 0){
          this.selectedExpandBy = this.expandByObj.key
        }
      }else{
        this.selectedExpandBy = ""
      }
    }
  },

  mounted() {
    // Set the initial number of items
    this.paymentLinksData = cloneDeep(this.paymentLinksDataFromStore);
    this.selectedCountry = this.paymentMethodsDataFromStore[0];
  },

  destroyed() {
    // document.body.classList.remove("dynamic-body");
  },

  methods: {
    ...paymentMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    sortByDate(a, b, key) {
      if (key == "created_at") {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1 - d2;
      }
    },

    updateCountry(val){
      this.selectedCountry = val;
    },
    updateMethod(val){
      this.selectedmethod = val;
    },
    filterTransactionByMor() {
      var from_date = this.pickerDates.startDate
      var to_date = this.pickerDates.endDate
      if (this.mor_filter == "") {
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          source:this.selectedChannel,
          frequency:this.appliedFilter,
          recon_type:"reconciled"
        });
      } else {
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          source:this.selectedChannel,
          frequency:this.appliedFilter,
          recon_type:"reconciled"
        });
      }
    },
    updateAnalytics(filter) {
      this.appliedAnalytics = filter;
      this.updateColumns();
       
      // var from_date = this.pickerDates.startDate
      // var to_date = this.pickerDates.endDate
      // this.$store.dispatch(`settlements/${FETCH_RECONCILIATION_SUMMARY_RESULTS}`, {
      //   pageIndex: 1,
      //   pagesize: 10,
      //   from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
      //   to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
      //   source:this.selectedChannel,
      //   frequency:this.appliedFilter,
      //   recon_type:"reconciled"
      // });
    },
    fetchTransactionOnEmptyFilter() {
      if (this.mor_filter == "") {
        var from_date = this.pickerDates.startDate
        var to_date = this.pickerDates.endDate
        this.$store.dispatch(`settlements/${FETCH_SETTLEMENTS_SUMMARY_RESULTS}`, {
          pageIndex: 1,
          pagesize: 10,
          from_date:new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate(), 0, 0, 0 ).getTime(),
          to_date:new Date(to_date.getFullYear(), to_date.getMonth(), to_date.getDate(), 23, 59, 59 ).getTime(),
          source:this.selectedChannel,
          frequency:this.appliedFilter,
          recon_type:"reconciled"
        });
      }
    },
    convertCountryToCurrency(val){
      if(val == "VN"){
          return "Vietnam"
      }else if(val== "TH"){
        return "Thailand"
      }else if(val== "SG"){
        return "Singapore"
      }else if(val== "ID"){
        return "Indonesia"
      }else if(val== "MY"){
        return "Malaysia"
      }else if(val== "TW"){
        return "Taiwan"
      }else if(val== "PH"){
        return "Philippines"
      }else if(val== "JP"){
        return "Japan"
      }else if(val== "KR"){
        return "Korea"
      }else if(val=="KE"){
        return "Kenya"
      }else if(val=="ZA"){
        return "South Africa"
      }else if(val=="NG"){
        return "Nigeria"
      }else if(val== "GLOBAL"){
        return "GLOBAL"
      }else if(val== ""){
        return ""
      }
    },
    convertkeyNamesTomethods(val){
      if(val == "CROSS_BORDER"){
          return "Cross Border"
      }else if(val== "BANK_TRANSFER"){
        return "Bank Transfer"
      }else if(val== "INT_CREDIT_CARD"){
        return "Credit Card"
      }else if(val== "ATM_CARD"){
        return "ATM Card"
      }else if(val== "INSTALLMENT"){
        return "Installment"
      }else if(val== "NET_BANKING"){
        return "Net Banking"
      }else if(val== "QR_CODE"){
        return "QR Code"
      }else if(val== "MOBILE_BANKING"){
        return "Net Banking(Mobile)"
      }else if(val== "DIRECT_BANK_TRANSFER"){
        return "Direct Bank Transfer"
      }else if(val== "WALLET"){
        return "Wallet"
      } else {
        return val
      }
    },
    toggleSwitch(value, data) {
      if (value != data.is_enabled) {
        var default_data = {
            "action_type": "UPDATE_DEFAULT_STATUS",
            "method_key": data.key,
            "method_sub_type": data.sub_type,
            "is_enabled": value
        }
        var enable_data = {
            "action_type": "UPDATE_ENABLE_STATUS",
            "method_key": data.key,
            "method_sub_type": "",
            "is_enabled": value
        }
        var count = 0;
        var selectedChannelName = data.channel_key
        this.$store.dispatch(`crossBorderPaymentMethods/${CROSS_BORDER_UPDATE_ENABLE_AND_DEFAULT_STATUS}`, {
          statusData: enable_data,
          countryForMethod: selectedChannelName
        }).then(() => {
            if(count == 0){
               this.$store.dispatch(`crossBorderPaymentMethods/${CROSS_BORDER_UPDATE_ENABLE_AND_DEFAULT_STATUS}`, {
                statusData: default_data,
                countryForMethod: selectedChannelName
              }).then(() => {
                this.$store.dispatch(`crossBorderPaymentMethods/${FETCH_CROSS_BORDER_PAYMENT_METHODS}`);
                this.paymentLinksData = cloneDeep(this.paymentLinksDataFromStore);
              })
            }
        })
        .catch((error) => {
          console.log(error);
          // failed while adding webhook
        });
      }
    },
    openFilter(){
      this.showFilterSection = !this.showFilterSection
    },
    saveFilter(){
      // var from_date = moment(new Date(this.pickerDates.startDate.getFullYear(), this.pickerDates.startDate.getMonth(), this.pickerDates.startDate.getDate(), 0, 0, 0 )).format("YYYY-MM-DD HH:mm:ss");
      // var to_date = moment(new Date(this.pickerDates.endDate.getFullYear(), this.pickerDates.endDate.getMonth(), this.pickerDates.endDate.getDate(), 23, 59, 59 )).format("YYYY-MM-DD HH:mm:ss");
      // this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_STATS}`, { filter: this.filter, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      // this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: this.filter, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      // this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: this.filter, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      this.showFilterSection = false;
      if(this.filter.method.length > 0 || this.filter.channel.length > 0 || this.filter.currency.length > 0) {
        this.isFilterApplied = true;
      } else {
        this.isFilterApplied = false;
      }
    },
    clearFilter(){
      this.filter = {
        currency:[],
        method:[],
        channel:[]
      }
      // var from_date = moment(new Date(this.pickerDates.startDate.getFullYear(), this.pickerDates.startDate.getMonth(), this.pickerDates.startDate.getDate(), 0, 0, 0 )).format("YYYY-MM-DD HH:mm:ss");
      // var to_date = moment(new Date(this.pickerDates.endDate.getFullYear(), this.pickerDates.endDate.getMonth(), this.pickerDates.endDate.getDate(), 23, 59, 59 )).format("YYYY-MM-DD HH:mm:ss");
      // this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_STATS}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      // this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      // this.$store.dispatch(`remittancesDashboard/${FETCH_REMITTANCES_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: {}, from_date, to_date, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
      this.showFilterSection = false;
      this.isFilterApplied = false;
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"  :items="items" />
    <div class="row">
      <div class="col-lg-12">
       <!-- <div class="btn-group" style="margin-left: 15px; vertical-align: top;">
          <button type="button" id="feeMismatch" class="btn btn-sm" @click="updateAnalytics('feeMismatch')" :class="appliedAnalytics==='feeMismatch' ? 'btn-primary': 'btn-outline-primary'">Fee Mismatch</button>
          <button type="button" id="reconSummary" class="btn btn-sm" @click="updateAnalytics('reconSummary')" :class="appliedAnalytics==='reconSummary' ? 'btn-primary': 'btn-outline-primary'">Recon Summary</button>
        </div> -->
      </div>
      <div class="col-lg-12">
            <div class="row pb-2">
              <!-- <div class="col-sm-12 col-lg-3 col-md-3 d-inline-block">
              </div> -->
              <div class="check-mobile col-sx-5 col-sm-12 col-lg-9 col-md-9">
              </div>
              <!-- <div class="col-sm-12 col-lg-3 col-md-3 d-flex">
                <multiselect
                  v-model="expandByObj"
                  placeholder="Expand By"
                  :searchable="true"
                  :options="expandBy"
                  track-by="value"
                  label="value"
                  :multiple="false"
                  class="custom-multiselect cursor-pointer sm-multiselect"
                  :taggable="true"
                ></multiselect>
                  <div class="ml-3 d-md-inline-block">
                    <div style="width: max-content; height:35px;" class="filter_button" @click="openFilter()" ><FilterBtn :isActive="isFilterApplied" /></div>
                    <div v-if="showFilterSection" class="filter-section">
                      <div class="bottom-section float-right">
                          <div @click="openFilter()" class="mb-2"><FilterBtn :isActive="isFilterApplied" /></div>
                      </div>
                      <div class="my-3 h-50" style="margin-left: 4%; border-bottom: 1px solid #F0F0F0; padding-bottom: 20px;">
                          <button @click="saveFilter()" class="btn btn-primary btn-sm">
                            Apply
                          </button>
                          <button @click="clearFilter()" style="padding-left: 20px;" class="mr-2 btn btn-sm">Clear Filter</button>
                      </div>
                      <div class="top-section">
                          <div class="filter_channel">
                            <div class="filter_heading" style="padding-left: 20px;">COUNTRY</div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_vietnam" value="VND" v-model="filter.currency">
                                <label for="filter_vietnam">Vietnam (VND)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_thailand" value="THB" v-model="filter.currency">
                                <label for="filter_thailand">Thailand (THB)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_singapore" value="SGD" v-model="filter.currency">
                                <label for="filter_singapore">Singapore (SGD)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_indonesia" value="IDR" v-model="filter.currency">
                                <label for="filter_indonesia">Indonesia (IDR)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_malaysia" value="MYR" v-model="filter.currency">
                                <label for="filter_malaysia">Malaysia (MYR)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_philippines" value="PHP" v-model="filter.currency">
                                <label for="filter_philippines">Philippines (PHP)</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_taiwan" value="TWD" v-model="filter.currency">
                                <label for="filter_taiwan">Taiwan (TWD)</label>
                              </div>
                          </div>
                          <div class="filter_method"> 
                              <div class="filter_heading" style="padding-left: 20px;">PAYMENT METHODS</div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_wallet" value="WALLET" v-model="filter.method">
                                <label for="filter_wallet">Wallet</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_credit_card" value="INT_CREDIT_CARD" v-model="filter.method">
                                <label for="filter_credit_card">Credit Card</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_atm_card" value="ATM_CARD" v-model="filter.method">
                                <label for="filter_atm_card">ATM Card</label>
                              </div>
                          </div>
                          <div class="filter_channels"> 
                              <div class="filter_heading" style="padding-left: 20px;">PAYMENT CHANNELS</div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_STRIPE" value="STRIPE" v-model="filter.channel">
                                <label for="filter_STRIPE">STRIPE</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;">  
                                <input type="checkbox" id="filter_2C2P" value="2C2P" v-model="filter.channel">
                                <label for="filter_2C2P">2C2P</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_EPAY" value="EPAY" v-model="filter.channel">
                                <label for="filter_EPAY">EPAY</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_MOMOPAY" value="MOMOPAY" v-model="filter.channel">
                                <label for="filter_MOMOPAY">MOMOPAY</label>
                              </div>
                              <div class="checkbox-group" style="padding-left: 20px;"> 
                                <input type="checkbox" id="filter_GBPRIMEPAY" value="GBPRIMEPAY" v-model="filter.channel">
                                <label for="filter_GBPRIMEPAY">GBPRIMEPAY</label>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-2" style="border-right: 1px solid #e2e2e2;">
                <div class=""
                  v-for="(data, index) in paymentMethodsDataFromStore"
                  :key="index">
                    <div @click="updateCountry(data)" class="country_list"
                    :class="{ 'active': selectedCountry == data }">
                        {{ convertCountryToCurrency(data) }}
                    </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-10" style="min-height: 60vh;">
                <ul role="tablist" class="nav nav-tabs nav-tabs-custom">
                  <li role="presentation" class="nav-item"
                    v-for="(data, index) in selectedmethodArray"
                    :key="index" @click="updateMethod(data)">
                    <a role="tab" href="#" target="_self" :class="{ 'active': selectedmethod == data }" class="nav-link p-3"> {{convertkeyNamesTomethods(data)}}</a>
                  </li>
                </ul>
                <!-- <b-tabs nav-class="nav-tabs-custom"> -->
                    <!-- Payment Channel tab -->
                    <!-- <b-tab
                      v-for="(data, index) in selectedmethodArray"
                      :key="index"
                      :title="data"
                      :active="selectedmethod == data"
                      :aria-posinset="index+1"
                      @click="updateMethod(data)"
                      title-link-class="p-3"
                    >
                    </b-tab>
                  </b-tabs> -->
                  <div class="row">
                    <div
                      class="col-xl-3 col-md-4 col-lg-4 col-sm-6 dynamic-card-parent"
                      v-for="(data, index) in listOfMethods"
                      :key="index"
                    >
                      <div
                        class="card border dynamic-card"
                      >
                        <div class="card-body text-center card-body-custom">
                          <div class="">
                          <div class="">
                            <div class="avatar-background avatar-lg"></div>
                            <img
                              :src="`${data.logo}`"
                              alt
                              class="avatar-lg"
                              style="object-fit: contain;position: relative;"
                            />
                            <div class="media-body media-body-custom text-left">
                              <p class="text-muted mb-0 mt-2 d-inline-block">
                                {{ data.name.toLowerCase() == 'paypal' ? 'PayPal' : data.name.replace(/via/g, "By") }}
                              </p>
                               <switches
                                :value="data.is_enabled"
                                color="primary"
                                class="ml-1 font-size-10 custom_switch"
                                style="position: absolute; right: 0px; top: 10px;"
                                 @input="toggleSwitch($event, data)"
                              ></switches>
                              <!-- <img v-if="!data.is_enabled" src="@/assets/images/arrow.png" alt />
                              <img v-if="data.is_enabled" src="@/assets/images/tick.png" alt /> -->
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <!-- </b-tab> -->
            <!-- </b-tabs> -->
      </div>
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .nav-tabs > li > a{
  padding-top: 0px !important;
}
::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep .filter_button button{
  height: 100%;
}
::v-deep .filter_button button:focus,.filter_button button:active {
  outline: none !important;
  box-shadow: none;
}

.search-button{
  background: #EAEAEA;
  border-color: #EAEAEA;
  color: #B3AFAF;
}
::v-deep .table-custom .table.b-table > thead > tr > .table-b-table-default{
  font-size:12px;
  border-right: 1px solid #D9D9D9;
  background-color: #F5F5F5;
}
::v-deep  .custom-multiselect .multiselect__tags{
  border: 0.5px solid #00246414 !important;
}
::v-deep .main-content {
    overflow: visible;
}
::v-deep  .datepicker-custom .reportrange-text{
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  font-size: 13px;
  color: #000;
  height: auto;
  padding: 5px 10px;
} 
input#file-upload-button{
  cursor: pointer;
}
 .filter-section{
      box-shadow: 0px 3px 10px #0000001a;
      background: #fff;
      position: absolute;
      top: 0px;
      z-index: 9;
      width: 380px;
      right:0px;
  }
  .top-section{
      border-bottom: 1px solid #F0F0F0;
      padding: 15px;
      padding-left: 5px;
      padding-top: 0px;
  }
  .clear_filter{
    color: #BFBFBF;
    display: inline-block;
    margin-left: 15px;
    font-size: 13px;
    cursor: pointer;
  }
  .bottom-section{
      padding: 15px;
      padding-left: 35px;
  }
  .filter_heading{
      color: #BFBFBF;
      padding-bottom: 15px;
      font-size: 13px;
      padding-top:10px ;
  }
  .checkbox-group{
    position: relative;
    display: inline-block;
  }
  .checkbox-group input{
    display: none;
  }
  .checkbox-group  label {
    cursor: pointer;
    font-size: 13px;
  }
  .checkbox-group  label:before {
    content:'';
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #DAD9D9;
    border-radius: 4px;
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    bottom:1px;
  }
  .checkbox-group input:checked+label:before {
    background-color: #252B3A;
    border: 1px solid #252B3A;
  }

  .checkbox-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 25px;
    width: 5px;
    height: 9px;
    border: 1px solid #fff;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
  }
  .filter_channel,.filter_method{
    display: block;
    border-bottom: 1px solid #F0F0F0;
  }
  .country_list{
    padding: 5px 25px;
    font-size: 16px;
    color: #B3AFAF;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .country_list.active{
    color: inherit;
    background: #eeeeee;
    border-radius: 4px;
  }
.card-body-custom{
  padding:10px;
  cursor: pointer;
}
.media-body-custom{
  border-top: 1px solid #DCE8FC;
  font-size: 12px;
  position: relative;
}
.media-body-custom img{
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0px;
  top: 10px;
}
.avatar-background{
    background-image: url("../../../assets/images/dots.png");
    background-size: 60px;
    background-position-x: 25%;
    background-position-y: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0px;
}
@media (min-width: 1550px) {
    .avatar-background{
      background-position-x: 30%;
  }
}
@media (max-width: 575px) {
    .avatar-background{
      background-position-x: 30%;
  }
}
.dynamic-card{
  width: 88%;
  margin: auto;
  margin-top: 30px;
}
::v-deep .custom_switch div:after{
    height: 14px;
    width: 14px;
    margin-left: -15px;
    top: 1px;
    background-color: #fff !important;
}
::v-deep .custom_switch div{
    height: 17px;
    background-color: #33b24a;
}
::v-deep .custom_switch.vue-switcher-theme--default.vue-switcher-color--primary.vue-switcher--unchecked div:after{
  margin-left: -13px;
}
 @media (max-width: 767px) {
  .check-mobile{
    margin-top: 20px;
  }
}
</style>